<template>
  <div>
    <slot :id="id" name="label">
      <label v-if="label" :for="id" class="form-label">
        {{ label }}
      </label>
    </slot>

    <div class="input-group relative w-full">
      <div v-if="inputGroup" class="input-group-text">
        {{ inputGroup }}
      </div>

      <div
        v-if="!inputGroup"
        class="input-group-text cursor-pointer"
        @click="onClickBrowseFiles"
      >
        <i class="fal fa-paperclip fa-lg" />
      </div>

      <div
        v-if="!inputGroup"
        class="input-group-text cursor-pointer"
        @click="onClickDownload"
      >
        <i class="fal fa-download fa-lg" />
      </div>

      <TomSelect
        :model-value="value"
        class="w-full file-input"
        :multiple="true"
        :disabled="disabled || readonly"
        :options="{
          placeholder: placeholder,
          allowEmptyOption: allowEmptyOption,
          labelField: labelField,
          valueField: valueField,
          options: displayedOptions,
          plugins: plugins,
          openOnFocus: false
        }"
        :class="[inputClass, { 'has-errors': hasErrors }]"
        @update:modelValue="onChange"
      />

      <div
        v-if="inputGroup"
        class="input-group-text cursor-pointer"
        @click="onClickBrowseFiles"
      >
        <i class="fal fa-paperclip fa-lg" />
      </div>

      <div
        v-if="inputGroup"
        class="input-group-text cursor-pointer"
        @click="onClickDownload"
      >
        <i class="fal fa-download fa-lg" />
      </div>
    </div>

    <VErrors :errors="errors" />

    <DeleteModal
      v-if="isModalVisible"
      :id="id"
      :message="$t('app.delete_item', { name: deletable.name })"
      :is-deleting="isDeleting"
      @click:cancel="onClickCancel"
      @click:delete="onClickDelete"
    />
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "@/store";
// Composables
import useDelete from "@/composables/useDelete";
import useInputs from "@/composables/useInputs";
import useDisplay from "@/composables/useDisplay";
import useDownload from "@/composables/useDownload";
// Components
import DeleteModal from "@/components/modals/DeleteModal";
import VErrors from "@/components/inputs/VErrors";

export default {
  components: {
    DeleteModal,
    VErrors
  },
  props: {
    /**
     * @NOTE
     * Default value should be an empty array if multiple prop is true
     */
    modelValue: {
      type: [String, Number, Array],
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    inputClass: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    inputGroup: {
      type: String,
      default: ""
    },
    options: {
      type: Array,
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: false
    },
    allowEmptyOption: {
      type: Boolean,
      default: false
    },
    labelField: {
      type: String,
      default: "name"
    },
    valueField: {
      type: String,
      default: "id"
    },
    accept: {
      type: String,
      default: "*"
    }
  },
  emits: ["update:modelValue", "upload"],
  setup(props, context) {
    // Misc
    const store = useStore();

    // Composables
    const { downloadFile } = useDownload();
    const { and } = useDisplay();
    const { id, hasErrors } = useInputs(props);
    const {
      isModalVisible,
      onClickDelete,
      isDeleting,
      deletable,
      onClickConfirmDelete,
      onClickCancelDelete
    } = useDelete({
      endpoint: "platform.files",
      modalId: `#${id}`
    });

    // Data
    const uploadedFiles = ref([]);

    // Comuted
    const value = computed(() => {
      return Array.isArray(props.modelValue)
        ? props.modelValue
        : `${props.modelValue}`;
    });

    const plugins = computed(() => {
      if (props.readonly || props.disabled) {
        return {};
      }

      return {
        remove_button: {}
      };
    });

    const displayedOptions = computed(() => {
      return [...props.options, ...uploadedFiles.value];
    });

    const maxUploadSize = computed(() => {
      return store.getters["app/max_upload_size"];
    });

    // Methods
    const browseFiles = () => {
      if (props.readonly) {
        return;
      }

      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", props.accept);
      input.click();
      input.addEventListener("input", uploadFile);
      return false;
    };

    const uploadFile = async e => {
      const file = getRightSizeFiles(e);

      if (!file) {
        return;
      }

      const formData = new FormData();
      formData.append("file", file);

      const response = await store.dispatch("api/request", {
        endpoint: "platform.files.upload",
        data: formData
      });

      const uploadedFile = response?.payload?.data;

      if (uploadedFile) {
        uploadedFiles.value.push(uploadedFile);

        if (props.multiple) {
          emitModelValue([...props.modelValue, uploadedFile[props.valueField]]);
        } else {
          emitModelValue(uploadedFile[props.valueField]);
        }

        context.emit("upload", uploadedFile[props.valueField]);
      }
    };

    const emitModelValue = value => {
      context.emit("update:modelValue", value);
    };

    const getRightSizeFiles = e => {
      const file = e.path[0].files[0];
      const fileSize = (file.size / 1024 / 1024).toFixed(4);

      if (fileSize > maxUploadSize) {
        return;
      }

      return file;
    };

    const onChange = value => {
      const isRemoved = isFileRemoved(value);

      if (isRemoved) {
        const file = getFile(value);

        if (file) {
          onClickConfirmDelete(file);
        }
      }
    };

    const getFile = value => {
      if (props.multiple) {
        const files = props.modelValue.filter(x => {
          return !value.includes(`${x}`);
        });

        const files2 = displayedOptions.value.filter(option => {
          return files.includes(option[props.valueField]);
        });

        return {
          id: files2.map(x => x.id).toString(),
          name: and(files2.map(x => x.name).toString(), ",")
        };
      }

      return displayedOptions.value.find(option => {
        return option[props.valueField] == props.modelValue;
      });
    };

    const isFileRemoved = value => {
      return props.multiple
        ? props.modelValue.length - value.length > 0
        : value.length === 0;
    };

    const deleteFile = async () => {
      await onClickDelete(deletable.value);

      clearOptions();

      if (props.multiple) {
        const files = deletable.value.id.split(",");
        const files2 = props.modelValue.filter(x => {
          return !files.includes(`${x}`);
        });
        context.emit("update:modelValue", files2);
      } else {
        context.emit("update:modelValue", "");
      }
    };

    const clearOptions = () => {
      if (props.multiple) {
        const files = deletable.value.id.split(",");
        uploadedFiles.value = uploadedFiles.value.filter(file => {
          return !files.includes(`${file.id}`);
        });
      } else {
        uploadedFiles.value = uploadedFiles.value.filter(file => {
          return file.id !== deletable.value.id;
        });
      }
    };

    const onClickDownload = async () => {
      if (props.multiple) {
        const files = props.options?.filter(file => {
          return props.modelValue?.includes(file.id);
        });

        files.forEach(file => {
          download(file?.download_path);
        });

        return;
      }

      const file = props.options?.find(file => file.id === props.modelValue);
      download(file?.download_path);
    };

    const download = path => {
      if (!path) {
        return;
      }

      downloadFile(path);
    };

    return {
      plugins,
      onClickBrowseFiles: browseFiles,
      displayedOptions,
      value,
      onChange,
      onClickDelete: deleteFile,
      onClickDownload,
      // useDelete
      isModalVisible,
      deletable,
      isDeleting,
      onClickCancel: onClickCancelDelete,
      // useInputs
      hasErrors,
      id
    };
  }
};
</script>

<style scoped lang="scss">
.has-errors ~ :deep(.tom-select .ts-input) {
  @apply border-theme-6;
}

.file-input ~ :deep(.tom-select .ts-input) {
  background-image: none;
}

.file-input ~ :deep(.tom-select .ts-input.disabled) {
  opacity: 1;
  background-color: rgba(247, 250, 252, 1);
}

.file-input ~ :deep(.tom-select .ts-input.disabled),
.file-input ~ :deep(.tom-select .ts-input.disabled *) {
  cursor: not-allowed !important;
}
</style>
