<template>
  <VModal
    @click:cancel="$emit('click:cancel')"
    @click:confirm="$emit('click:confirm')"
  >
    <template #content>
      <div class="text-center">
        <slot name="icon">
          <AlertTriangleIcon class="w-16 h-16 text-theme-12 mx-auto mt-3" />
        </slot>

        <div class="text-3xl mt-5">
          {{ $t("app.are_you_sure") }}
        </div>

        <div class="text-gray-600 mt-2">
          <!-- eslint-disable-next-line -->
          <span v-html="warning" />
        </div>
      </div>
    </template>

    <template #actions>
      <slot name="actions">
        <div class="px-5 pb-8 text-center">
          <VButton
            class="btn-primary"
            :label="$t('app.ok')"
            @click="$emit('click')"
          />
        </div>
      </slot>
    </template>
  </VModal>
</template>

<script>
// Components
import VModal from "@/components/modals/VModal";
import VButton from "@/components/VButton";

export default {
  components: {
    VModal,
    VButton
  },
  props: {
    warning: {
      type: String,
      default: ""
    }
  }
};
</script>
