<template>
  <VModal>
    <template #header>
      <div class="p-4 border-b border-gray-200 dark:border-dark-5">
        <span class="font-medium text-lg">
          <slot name="header" />
        </span>
      </div>
    </template>

    <template #content>
      <slot name="content" />
    </template>

    <template #actions>
      <div
        class="flex justify-end p-3 border-t border-gray-200 dark:border-dark-5"
      >
        <VButton
          class="btn-outline-secondary mr-1"
          :label="$t('app.cancel')"
          @click="$emit('click:cancel')"
        />

        <VButton
          class="btn-primary"
          :is-loading="isLoading"
          :label="label"
          @click="$emit('click:confirm')"
        />
      </div>
    </template>
  </VModal>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import VModal from "@/components/modals/VModal";
import VButton from "@/components/VButton";

export default {
  components: {
    VModal,
    VButton
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    labelConfirmBtn: {
      type: String,
      default: ""
    },
    size: {
      type: String,
      default: "",
      validator: value => {
        return ["modal-sm", "", "modal-lg", "modal-xl"].includes(value);
      }
    }
  },
  emits: ["click:cancel", "click:confirm"],
  setup(props) {
    const { t } = useI18n();
    const label = computed(() => {
      return props.labelConfirmBtn ? props.labelConfirmBtn : t("app.confirm");
    });

    return { label };
  }
};
</script>
