import { useStore } from "@/store";

export default function useDownload() {
  const store = useStore();

  const download = (endpoint, pathParams = {}, queryParams = {}) => {
    const url = getURL(endpoint, pathParams);
    downloadURL(url, queryParams);
  };

  const getURL = (endpoint, pathParams = {}) => {
    const { url } = store.getters["api/getEndpoint"](endpoint, pathParams);
    return url;
  };

  const downloadURL = (baseURL, queryParams = {}) => {
    const query = {
      ...queryParams,
      api_token: store.getters["user/api_token"],
      DOWNLOAD_FILE: true
    };

    const url = `${baseURL}?${new URLSearchParams(query).toString()}`;

    window.open(url, "_blank");
  };

  const downloadFile = url => {
    const baseURL = store.getters["api/baseURL"];

    if (!url || !baseURL) {
      return;
    }

    downloadURL(`${baseURL}${url}`);
  };

  return {
    downloadFile,
    downloadURL,
    download,
    getURL
  };
}
