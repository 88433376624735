<template>
  <div class="flex items-center">
    <component
      :is="getComponent()"
      :model-value="answered"
      :name="question.id"
      :readonly="readonly"
      @update:modelValue="$emit('update', $event)"
    >
      <template #label>
        {{ getText(answer?.texts, "name") }}
      </template>
    </component>

    <VAction
      v-if="displayCorrectAnswers && isCorrectAnswer"
      icon="correct"
      color="text-theme-9"
    />
  </div>
</template>

<script>
import { computed } from "vue";
// Composables
import useTexts from "@/composables/useTexts";
import useOptions from "@/composables/useOptions";
// Components
import VRadio from "@/components/inputs/VRadio";
import VCheckbox from "@/components/inputs/VCheckbox";
import VAction from "@/components/tables/VAction";

export default {
  components: {
    VRadio,
    VCheckbox,
    VAction
  },
  props: {
    question: {
      type: Object,
      required: true,
      validator: value => "id" in value && "answer_type" in value
    },
    answer: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    answered: {
      type: Boolean,
      default: false
    },
    displayCorrectAnswers: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update"],
  setup(props) {
    // Composables
    const { ALL_OPTIONS } = useOptions();
    const { getText } = useTexts();

    // Computed
    const isCorrectAnswer = computed(() => {
      return props.answer.correct_answer === ALL_OPTIONS.YES.value;
    });

    // Methods
    const getComponent = () => {
      switch (props.question?.answer_type) {
        case ALL_OPTIONS.TRUE_FALSE.value:
        case ALL_OPTIONS.SINGLE_CHOICE.value:
        case ALL_OPTIONS.FOUR_CHOICES.value:
        case ALL_OPTIONS.THREE_CHOICES.value:
          return "VRadio";
        case ALL_OPTIONS.MULTIPLE_CHOICE.value:
          return "VCheckbox";
        default:
          return "";
      }
    };

    return {
      isCorrectAnswer,
      getComponent,
      // useTexts
      getText
    };
  }
};
</script>
