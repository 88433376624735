<template>
  <div>
    <VButton
      class="btn-primary"
      :label="$t('app.submit')"
      :disabled="disabled"
      @click="showModal"
    />

    <FormModal
      v-if="isModalVisible"
      id="submit-evaluation"
      size="modal-lg"
      :is-loading="isSubmitting"
      @click:cancel="hideModal"
      @click:confirm="submitEvaluation"
    >
      <template #header>
        {{ $t("app.submit_evaluation") }}
      </template>

      <template #content>
        <div class="mb-2">
          {{ $t("app.confirm_password_msg") }}
        </div>

        <VText
          v-model="v.my_password.$model"
          type="password"
          :placeholder="instructorPlaceholder"
          :errors="v.my_password.$errors"
          @keyup.enter="submitEvaluation"
        />

        <VText
          v-if="isObservation"
          v-model="v.other_password.$model"
          class="mt-4"
          type="password"
          :placeholder="learnerPlaceholder"
          :errors="v.other_password.$errors"
          @keyup.enter="submitEvaluation"
        />
      </template>
    </FormModal>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { required } from "@/i18n/i18n-validators";
// Composables
import useRequest from "@/composables/useRequest";
import useVerifyPassword from "@/composables/useVerifyPassword";
import useModal from "@/composables/useModal";
import useForm from "@/composables/useForm";
// Components
import VButton from "@/components/VButton";
import FormModal from "@/components/modals/FormModal";
import VText from "@/components/inputs/VText";

export default {
  components: {
    VButton,
    FormModal,
    VText
  },
  props: {
    /**
     * Registration Evaluation ID
     */
    id: {
      type: [Number, String],
      required: true
    },
    comments: {
      type: String,
      default: ""
    },
    fileIds: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isObservation: {
      type: Boolean,
      default: false
    },
    userId: {
      type: [String, Number],
      default: ""
    }
  },
  setup(props, context) {
    // Misc
    const { t } = useI18n();

    // Data
    const isSubmitting = ref(false);
    const value = ref({
      my_password: "",
      other_password: ""
    });
    const rules = ref({
      my_password: { required },
      other_password: props.isObservation ? { required } : {}
    });

    // Computed
    const commentType = computed(() => {
      return props.isObservation ? "observer_comments" : "learner_comments";
    });
    const instructorPlaceholder = computed(() => {
      return props.isObservation
        ? t(`app.instructor_${verify_by.value}`)
        : placeholder.value;
    });
    const learnerPlaceholder = computed(() => {
      return props.isObservation
        ? t(`app.learner_${verify_by.value}`)
        : placeholder.value;
    });

    // Composables
    // eslint-disable-next-line
    const { isModalVisible, showModal, hideModal } = useModal("#submit-evaluation");
    const { request: submit, isSuccess } = useRequest();
    const { placeholder, verify, verifyUser, verify_by } = useVerifyPassword();
    const { v, onSave } = useForm(props, context, { value, rules });

    // Methods
    const submitEvaluation = async () => {
      isSubmitting.value = true;

      const data = await onSave();

      if (!data) {
        isSubmitting.value = false;
        return;
      }

      const isVerified = await verify(data.my_password);

      if (!isVerified) {
        isSubmitting.value = false;
        return;
      }

      if (props.isObservation) {
        const isVerified2 = await verifyUser(props.userId, data.other_password);

        if (!isVerified2) {
          isSubmitting.value = false;
          return;
        }
      }

      const response = await submit({
        endpoint: "learning.evaluations.submit",
        pathParams: {
          id: props.id
        },
        data: {
          file_ids: props.fileIds.toString(),
          [commentType.value]: props.comments
        }
      });

      hideModal();
      isSubmitting.value = false;

      if (isSuccess(response)) {
        context.emit("submitted");
      }
    };

    return {
      submitEvaluation,
      isSubmitting,
      instructorPlaceholder,
      learnerPlaceholder,
      // useModal
      isModalVisible,
      showModal,
      hideModal,
      // useVerifyPassword
      verify_by,
      placeholder,
      // useForm
      v
    };
  }
};
</script>
