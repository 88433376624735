export default function useColor() {
  const getContrastingColor = hex => {
    if (!hex) {
      return "#000000";
    }

    const hexValue = hex.slice(1);
    const x = parseInt(hexValue.substring(0, 2), 16);
    const y = parseInt(hexValue.substring(2, 4), 16);
    const z = parseInt(hexValue.substring(4, 6), 16);
    // eslint-disable-next-line
    const value = ((x * 299) + (y * 587) + (z * 114)) / 1000;

    return value > 130 ? "#000000" : "#FFFFFF";
  };

  /**
   * Get a color from the HSLA format
   * @param {Number} h - hue
   * @param {Number} l - lightness
   * @param {Number} s - saturation
   * @param {Number} a - alpha (opacity)
   * @returns - color
   */
  const getColor = (h, l = 70, s = 50, a = 1) => {
    return `hsla(${h}, ${l}%, ${s}%, ${a})`;
  };

  const getRandomColors = (amount = 1) => {
    const colors = [];
    const step = 360 / amount;

    let hue = Math.random() * 360;

    for (let i = 0; i < amount; i++) {
      colors.push(getColor(hue));
      hue = getNextHue(hue, step);
    }

    return colors;
  };

  const getNextHue = (value, step, max = 360) => {
    const result = value + step;

    if (result > max) {
      return result - max;
    }

    return result;
  };

  const getStatusColor = status => {
    switch (status) {
      case "Active":
      case "Approved":
      case "Completed":
      case "Passed":
      case "Valid":
      case "Accepted":
        return "green status";

      case "Archived":
      case "Inactive":
      case "Non-obtainable":
      case "Paused":
      case "Rejected":
      case "Failed":
        return "red status";

      case "Under Review":
        return "yellow status";

      case "Obtainable":
      case "Pending":
      case "Incomplete":
        return "gray status";

      case "Awaiting Approval":
      case "Completion Approval Requested":
      case "Learner Completed":
      case "Observation Requested":
      case "Observation Scheduled":
      case "Pending Approval":
      case "Requested":
      case "Registration Requested":
        return "orange status";

      case "Cancelled":
      case "Denied":
      case "Expired":
      case "Withdrawn":
        return "dark status";

      case "In Progress":
      default:
        return "blue status";
    }
  };

  const getRGBStatusColor = status => {
    switch (status) {
      case "Active":
      case "Approved":
      case "Completed":
      case "Passed":
      case "Valid":
      case "Accepted":
        return "rgb(132, 204, 22)";

      case "Archived":
      case "Inactive":
      case "Non-obtainable":
      case "Paused":
      case "Rejected":
      case "Failed":
        return "rgb(220, 38, 38)";

      case "Under Review":
        return "rgb(250, 204, 21)";

      case "Obtainable":
      case "Pending":
      case "Incomplete":
        return "rgb(203, 213, 225)";

      case "Awaiting Approval":
      case "Completion Approval Requested":
      case "Learner Completed":
      case "Observation Requested":
      case "Observation Scheduled":
      case "Pending Approval":
      case "Requested":
      case "Registration Requested":
        return "rgb(249, 115, 22)";

      case "Cancelled":
      case "Denied":
      case "Expired":
      case "Withdrawn":
        return "rgb(51, 65, 85)";

      case "In Progress":
      default:
        return "rgb(30, 64, 175)";
    }
  };

  const getActionColor = action => {
    switch (action) {
      case "Added":
      case "Added User":
      case "Logged In":
        return "green action";

      case "Deleted":
      case "Removed User":
        return "red action";

      case "Updated":
        return "yellow action";

      default:
        return "blue action";
    }
  };

  return {
    getColor,
    getRGBStatusColor,
    getRandomColors,
    getStatusColor,
    getContrastingColor,
    getActionColor
  };
}
