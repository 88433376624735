import { computed, onMounted, watch } from "vue";
import { useStore } from "@/store";
// Composables
import useLocalizedInputs from "@/composables/useLocalizedInputs";
import useOptions from "@/composables/useOptions";
import useTexts from "@/composables/useTexts";
import useTags from "@/composables/useTags";

/**
 *
 * @param {Object} props - component props
 * @param {Object} context - component context
 * @param {Object} args
 *  @param {Ref<Object>} value - form value
 *  @param {Ref<Object>} value - form options
 *  @param {Ref<Object>} rules - form rules
 *  @param {Array} localizedFields - form localized fields
 *  @param {Array} relationFields - form localized fields
 *  @param {Ref<String>} lang - the selected lang
 * @returns {Object}
 */
export default function useForm(props, context, args = {}) {
  const {
    value,
    options,
    rules,
    localizedFields = [],
    relationFields = [],
    lang
  } = args;

  // Misc
  const store = useStore();

  // Composables
  const { ALL_OPTIONS } = useOptions();
  const { getTextValues } = useTexts();
  const { getTagValues } = useTags();
  const {
    v,
    onUpdate,
    stringifiedValue,
    clearLocalizedValue
  } = useLocalizedInputs(value, rules, localizedFields, lang);

  // Computed
  const isCreate = computed(() => action.value === "create");
  const action = computed(() => (props.data?.id ? "update" : "create"));
  const isSysAdmin = computed(() => store.getters["user/isSysAdmin"]);
  // eslint-disable-next-line
  const locked = computed(() => isCreate.value ? false : props.data?.sys_admin_locked === "Yes");
  // eslint-disable-next-line
  const isActive = computed(() => isCreate.value ? false : props?.data?.status === ALL_OPTIONS.ACTIVE.value);

  // Methods
  const setData = ignoreKeys => {
    if (!value?.value) {
      return;
    }

    let data = {
      ...props.data,
      ...getTextValues(props.data?.texts, localizedFields)
    };

    if (data?.tags) {
      data = {
        ...data,
        tags: getTagValues(data?.tags)
      };
    }

    Object.keys(value.value).forEach(key => {
      setOptions(data, key);
      setValue(data, key, ignoreKeys);
    });
  };

  const setOptions = (data, key) => {
    const [k] = relationFields
      .filter(field => field[key])
      .map(field => field[key]);

    if (k && data && data[k]) {
      options.value[key] = [data[k]];
    }
  };

  const setValue = (data, key, ignoreKeys) => {
    if (data && (ignoreKeys || data[key])) {
      value.value[key] = data[key];
    }
  };

  const onCancel = () => {
    v.value.$reset();
  };

  const onSave = async () => {
    const isValid = await v.value.$validate();

    if (!isValid) {
      return;
    }

    let result = stringifiedValue.value;

    if (result?.tags) {
      const tags = result.tags.filter(tag => tag);

      result = {
        ...result,
        tags: JSON.stringify(tags)
      };
    }

    context.emit("click:save", result);
    return result;
  };

  // Watch
  watch(
    () => props.data,
    () => {
      setData(true);
    }
  );

  // Lifecycle Hooks
  onMounted(() => {
    setData(false);
  });

  return {
    v,
    isActive,
    isSysAdmin,
    locked,
    onUpdate,
    action,
    setData,
    onCancel,
    onSave,
    isCreate,
    // useLocalizedInputs
    stringifiedValue,
    clearLocalizedValue
  };
}
