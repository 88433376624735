import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";
// Composables
import useRequest from "@/composables/useRequest";

export default function useVerifyPassword() {
  // Misc
  const { t } = useI18n();
  const store = useStore();

  // Composables
  const { request, isSuccess } = useRequest();

  // Computed
  const verify_by = computed(() => store.getters["app/verify_by"]);
  const placeholder = computed(() => t(`app.${verify_by.value}`));

  // Methods
  /**
   * Verify the logged in user's password or pin
   * @param {String} password
   * @returns {Boolean} true if verify is successful, false otherwise
   */
  const verify = async password => {
    if (!["pin", "password"].includes(verify_by.value)) {
      return false;
    }

    const response = await request({
      endpoint: `my.${verify_by.value}-verification`,
      data: {
        [verify_by.value]: password
      }
    });

    return isSuccess(response);
  };

  /**
   * Verify the user's password or pin
   * @param {Number} id - the user ID
   * @param {String} password
   * @returns {Boolean} true if verify is successful, false otherwise
   */
  const verifyUser = async (id, password) => {
    if (!["pin", "password"].includes(verify_by.value)) {
      return false;
    }

    const response = await request({
      endpoint: `platform.${verify_by.value}-verification`,
      pathParams: {
        id
      },
      data: {
        [verify_by.value]: password
      }
    });

    return isSuccess(response);
  };

  return {
    verify_by,
    verifyUser,
    placeholder,
    verify
  };
}
