<template>
  <div>
    <VTitle :title="$t('app.versions', 2)" class="overview-title" />

    <VCard>
      <template #content>
        <VAlert v-if="versions.length === 0" :text="$t('app.no_versions')" />

        <div v-else>
          <div
            v-for="(version, index) in versions"
            :key="version.id"
            class="flex items-center cursor-pointer"
            :class="{ 'mb-4': index !== versions.length - 1 }"
            @click="onClickRedirectVerson(version.id)"
          >
            <div class="border-l-2 border-theme-1 pl-4">
              <div class="font-medium">
                {{ $t("app.versions") }} {{ version.version }}
              </div>

              <div class="text-gray-600 text-xs">
                {{ getDate(version) }}
              </div>
            </div>

            <VChip
              :text="$t(`app.${version.status}`)"
              class="ml-auto"
              :class="getStatusColor(version.status)"
            />
          </div>
        </div>
      </template>
    </VCard>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
// Composables
import useDisplay from "@/composables/useDisplay";
import useColor from "@/composables/useColor";
// Components
import VCard from "@/components/VCard";
import VChip from "@/components/VChip";
import VAlert from "@/components/VAlert";
import VTitle from "@/components/VTitle";

export default {
  components: {
    VCard,
    VTitle,
    VChip,
    VAlert
  },
  props: {
    route: {
      type: String,
      required: true
    },
    versions: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();
    const router = useRouter();

    // Composables
    const { getStatusColor } = useColor();
    const { formatDate } = useDisplay();

    // Methods
    const onClickRedirectVerson = id => {
      router.push({
        name: `${props.route}-overview`,
        params: {
          id
        }
      });
    };

    const getDate = version => {
      const statuses = {
        Archived: "archived_on",
        Active: "activated_on",
        "Under Review": "updated_on"
      };

      const dates = {
        Archived: version.date_archived,
        Active: version.date_activated,
        "Under Review": version.updated_at
      };

      const status = statuses[version?.status];
      const date = dates[version?.status];

      if (status && date) {
        return t(`app.${status}`, { date: formatDate({ date: date }) });
      }

      return t("app.updated_on", {
        date: formatDate({ date: version?.updated_at })
      });
    };

    return {
      getDate,
      onClickRedirectVerson,
      // useColor
      getStatusColor
    };
  }
};
</script>
