<template>
  <VCard>
    <template #title>
      <div class="text-xs uppercase font-light">
        {{ title }}
      </div>
    </template>

    <template #subtitles>
      <div class="font-medium mt-1">
        {{ trimHTML(getText(question?.texts, "details")) }}
      </div>

      <div
        v-if="question?.image?.view_path"
        class="mt-3 flex justify-center items-center"
      >
        <img :src="getAvatarURL(question?.image?.view_path)" />
      </div>
    </template>

    <template #title-actions>
      <div class="self-start text-theme-15 font-medium text-base">
        {{ question.grade }}

        <span class="lowercase">
          {{ $t("app.points") }}
        </span>

        ({{ getPercentage(question.grade) }}%)
      </div>
    </template>

    <template #content>
      <div v-if="question.answers" :class="{ 'mb-4': isObservation }">
        <Answer
          v-for="(answer, answerIndex) in question.answers"
          :key="answer.id"
          :class="{ 'mt-2': answerIndex !== 0 }"
          :question="question"
          :answered="isAnswered(answer?.id)"
          :answer="answer"
          :readonly="readonly"
          :display-correct-answers="displayCorrectAnswers"
          @update="updateAnswer(answer?.id, $event)"
        />
      </div>

      <div v-if="isObservation" class="my-2">
        <div class="border-b border-dashed" />

        <div class="-mt-3 flex justify-center">
          {{ $t("app.criteria") }}
        </div>
      </div>

      <div v-if="isObservation">
        <Criteria
          v-for="(criterion, criterionIndex) in question.criteria"
          :key="criterion.id"
          :class="{ 'mt-2': criterionIndex !== 0 }"
          :checked="isChecked(criterion?.id)"
          :question="question"
          :readonly="readonly"
          :criteria="criterion"
          @update="updateCriteria(criterion?.id, $event)"
        />
      </div>
    </template>

    <template #more-content>
      <VFile
        v-if="isObservation"
        v-model="file_id"
        :readonly="readonly"
        :options="registrationQuestion?.files ?? []"
        multiple
        class="mb-4"
        @upload="addFile"
      >
        <template #label>
          <VLine class="form-label" :label="$t('app.file_ids')" />
        </template>
      </VFile>

      <VComment
        v-model="comments"
        :label="$t('app.comments')"
        :readonly="readonly"
        @blur="onBlur"
      />
    </template>
  </VCard>
</template>

<script>
import { computed, inject, ref } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useDisplay from "@/composables/useDisplay";
import useTexts from "@/composables/useTexts";
import useOptions from "@/composables/useOptions";
import useRequest from "@/composables/useRequest";
// Components
import VCard from "@/components/VCard";
import Answer from "./Answer";
import Criteria from "./Criteria";
import VComment from "@/components/inputs/VComment";
import VLine from "@/components/VLine";
import VFile from "@/components/inputs/VFile";

export default {
  components: {
    VCard,
    VComment,
    Criteria,
    VFile,
    Answer,
    VLine
  },
  props: {
    index: {
      type: Number,
      required: true
    },
    registrationEvaluationId: {
      type: [String, Number],
      required: true
    },
    registrationQuestion: {
      type: Object,
      default: () => {}
    },
    question: {
      type: Object,
      required: true
    },
    isObservation: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    answersGiven: {
      type: Array,
      default: () => []
    },
    criteriaSucceeded: {
      type: Array,
      default: () => []
    },
    displayCorrectAnswers: {
      type: Boolean,
      default: false
    },
    totalGrade: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();
    const toast = inject("toast");

    // Composables
    const { getText } = useTexts();
    const { trimHTML, getAvatarURL } = useDisplay();
    const { request } = useRequest();
    const { ALL_OPTIONS } = useOptions();

    // Computed
    const title = computed(() => {
      const index = props.index;

      return props.isObservation
        ? `${t("app.requirements")} ${index}`
        : `${t("app.questions")} ${index}`;
    });

    const fileIds = computed(() => {
      const files = props.registrationQuestion?.files ?? [];
      const fileIds = files.map(({ id }) => id);

      return fileIds;
    });

    // Data
    const comments = ref(props.registrationQuestion?.comments ?? "");
    const file_id = ref(fileIds.value);
    const answers = ref([]);

    // Methods
    const updateAnswer = async (answer_id, value) => {
      answers.value.push(answer_id);
      let isDelete = false;

      // eslint-disable-next-line
      if (props.question?.answer_type === ALL_OPTIONS.MULTIPLE_CHOICE.value) {
        if (!value) {
          isDelete = true;

          const index = answers.value.indexOf(answer_id);
          answers.value.splice(index, 1);
        }
      } else {
        answers.value = [answer_id];
      }

      await request({
        endpoint: "learning.evaluations.submit-answer",
        pathParams: {
          id: props.registrationEvaluationId
        },
        data: {
          question_id: props.question?.id,
          answer_id,
          comments: comments.value,
          delete: isDelete
        }
      });
    };

    const updateCriteria = async (criteria_id, value) => {
      await request({
        endpoint: "learning.evaluations.submit-criteria",
        pathParams: {
          id: props.registrationEvaluationId
        },
        data: {
          question_id: props.question?.id,
          criteria_id,
          delete: !value
        }
      });
    };

    const addFile = async file_id => {
      await request({
        endpoint: "learning.evaluations.add-file",
        pathParams: {
          id: props.registrationEvaluationId
        },
        data: {
          question_id: props.question?.id,
          file_id
        }
      });
    };

    const isAnswered = answerId => {
      return props.answersGiven.some(answer => answer?.answer_id === answerId);
    };

    const isChecked = criteriaId => {
      return props.criteriaSucceeded.some(
        criteria => criteria.criteria_id === criteriaId
      );
    };

    const getPercentage = grade => {
      if (!props.totalGrade) {
        return 0;
      }

      const percentage = (grade / props.totalGrade) * 100;

      return percentage.toFixed(0);
    };

    const onBlur = () => {
      if (!comments.value) {
        return;
      }

      if (answers.value.length === 0) {
        toast.show(t("app.questionnaire_no_answer_comments_error"), "Failure");
        return;
      }

      const [answerId] = answers.value;
      updateAnswer(answerId, true);
    };

    return {
      onBlur,
      title,
      getPercentage,
      isChecked,
      isAnswered,
      comments,
      file_id,
      updateAnswer,
      updateCriteria,
      addFile,
      // useDisplay
      trimHTML,
      getAvatarURL,
      // useTexts
      getText
    };
  }
};
</script>
