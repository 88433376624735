<template>
  <div>
    <VCard>
      <template #content>
        <div class="flex justify-center mb-4">
          <SendIcon class="w-10 h-10 text-theme-1" />
        </div>

        <div class="flex justify-center">
          <VButton
            class="btn-primary"
            :label="$t('app.start')"
            @click="showModal"
          />
        </div>
      </template>
    </VCard>

    <WarningModal
      v-if="isModalVisible"
      :id="resource?.id"
      :warning="$t('app.quiz_start_msg')"
    >
      <template #actions>
        <div class="px-5 pb-8 text-center">
          <VButton
            class="btn-outline-secondary mr-2"
            :label="$t('app.cancel')"
            @click="hideModal"
          />

          <VButton
            class="btn-primary"
            :label="$t('app.start')"
            @click="startQuiz"
          />
        </div>
      </template>
    </WarningModal>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
// Composables
import useModal from "@/composables/useModal";
// Components
import VCard from "@/components/VCard";
import VButton from "@/components/VButton";
import WarningModal from "@/components/modals/WarningModal";

export default {
  components: {
    VCard,
    VButton,
    WarningModal
  },
  props: {
    resource: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    // Misc
    const router = useRouter();

    // Composables
    // eslint-disable-next-line
    const { isModalVisible, hideModal, showModal } = useModal(`#${props.resource?.id}`);

    // Methods
    const startQuiz = () => {
      hideModal();

      router.push({
        name: "quiz",
        params: {
          id: props.resource?.id
        }
      });
    };

    return {
      startQuiz,
      // useModal
      isModalVisible,
      showModal,
      hideModal
    };
  }
};
</script>
