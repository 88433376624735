import { inject, computed } from "vue";

export default function useInputs(props) {
  const _ = inject("lodash");

  // Data
  const id = _.uniqueId();

  // Computed
  const hasErrors = computed(() => {
    return props.errors && Object.keys(props.errors).length > 0;
  });

  return {
    id,
    hasErrors
  };
}
