<template>
  <div>
    <div v-if="files.length === 0">
      {{ $t("app.none") }}
    </div>

    <div v-else class="flex flex-wrap">
      <FileItem v-for="file in files" :key="file.id" :file="file" />
    </div>
  </div>
</template>

<script>
// Components
import FileItem from "@/components/FileItem";

export default {
  components: {
    FileItem
  },
  props: {
    files: {
      type: Array,
      default: () => []
    }
  }
};
</script>
