<template>
  <Tippy tag="a" class="tooltip" :content="displayedDateTime">
    {{ displayedDate }}
  </Tippy>
</template>

<script>
import { computed } from "vue";
// Composables
import useDisplay from "@/composables/useDisplay";

export default {
  props: {
    date: {
      type: String,
      required: true
    }
  },
  setup(props) {
    // Composables
    const { formatDate } = useDisplay();

    // Computed
    const displayedDate = computed(() => formatDate({ date: props.date }));
    const displayedDateTime = computed(() => {
      return formatDate({ date: props.date, displayTime: true });
    });

    return {
      displayedDate,
      displayedDateTime
    };
  }
};
</script>
