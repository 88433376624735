<template>
  <VCheckbox
    :model-value="checked"
    :readonly="readonly"
    @update:modelValue="$emit('update', $event)"
  >
    <template #label>
      {{ getText(criteria?.texts, "name") }}
    </template>
  </VCheckbox>
</template>

<script>
// Composables
import useTexts from "@/composables/useTexts";
// Components
import VCheckbox from "@/components/inputs/VCheckbox";

export default {
  components: {
    VCheckbox
  },
  props: {
    question: {
      type: Object,
      required: true,
      validator: value => "id" in value && "answer_type" in value
    },
    criteria: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update"],
  setup() {
    // Composables
    const { getText } = useTexts();

    return {
      // useTexts
      getText
    };
  }
};
</script>
