<template>
  <div>
    <Question
      v-for="(question, index) in questions"
      :id="`question-${index}`"
      :key="question.question?.id"
      :index="index + 1"
      :registration-evaluation-id="registrationEvaluationId"
      :is-observation="isObservation"
      :answers-given="question.answers_given"
      :criteria-succeeded="question.criteria_succeeded"
      :registration-question="question"
      :question="question.question"
      :readonly="readonly"
      :total-grade="totalGrade"
      :display-correct-answers="displayCorrectAnswers"
      :class="{ 'mt-8': index !== 0 }"
    />
  </div>
</template>

<script>
import { computed } from "vue";
// Components
import Question from "./Question";

export default {
  components: {
    Question
  },
  props: {
    registrationEvaluationId: {
      type: [String, Number],
      required: true
    },
    isObservation: {
      type: Boolean,
      default: false
    },
    questions: {
      type: Array,
      default: () => []
    },
    readonly: {
      type: Boolean,
      default: false
    },
    displayCorrectAnswers: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    /**
     * Calculate the total grade from all the questions passed in props
     * @returns {Number} the total grade
     */
    const totalGrade = computed(() => {
      const total = props.questions.reduce((acc, question) => {
        const grade = question?.question?.grade ?? 0;

        return acc + grade;
      }, 0);

      return total;
    });

    return {
      totalGrade
    };
  }
};
</script>
