<template>
  <div>
    <div class="flex justify-between items-center">
      <slot name="title" />

      <div v-if="isCompleted && !hideFeedback" class="flex">
        <FeedbackButton
          :id="evaluation?.id"
          :type="feedbackRatingTypes.EVALUATION"
          class="mr-2"
        />

        <RatingButton
          :id="id"
          :name="name"
          :type="feedbackRatingTypes.EVALUATION"
        />
      </div>
    </div>

    <div v-if="isLoading" class="flex justify-center">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
    </div>

    <div v-else>
      <div class="grid grid-cols-5 gap-8">
        <div class="col-span-5 lg:col-span-2">
          <EvaluationCard
            v-if="data?.registration_evaluation"
            :evaluation-type="type"
            :resource="data?.registration_evaluation"
            is-view
          >
            <template v-if="$slots['card-title']" #title="{ resource }">
              <slot name="card-title" :resource="resource" />
            </template>
          </EvaluationCard>

          <Attempts
            class="mt-8"
            :attempts="data?.other_attempts"
            :type="type"
          />

          <Versions
            v-if="isInstructor"
            class="mt-8"
            :route="route"
            :versions="data?.registration_evaluation?.evaluation?.versions"
          />
        </div>

        <div class="col-span-5 lg:col-span-3">
          <EvaluationContent v-if="isPending" :resource="data" />

          <Questions
            :questions="data?.questionnaire"
            :is-observation="isObservation"
            readonly
            :registration-evaluation-id="id"
            :display-correct-answers="displayCorrectAnswers"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import { useStore } from "@/store";
import { useI18n } from "vue-i18n";
// Composables
import useTexts from "@/composables/useTexts";
import useLesson from "@/composables/useLesson";
import useOptions from "@/composables/useOptions";
import useRequest from "@/composables/useRequest";
// Components
import EvaluationCard from "./EvaluationCard";
import EvaluationContent from "./EvaluationContent";
import Questions from "./Questions";
import Attempts from "./Attempts";
import Versions from "@/components/templates/Versions";
import FeedbackButton from "@/components/FeedbackButton";
import RatingButton from "@/components/RatingButton";
// Constants
import feedbackRatingTypes from "@/constants/feedbackRatingTypes";
import evaluationTypes from "@/constants/evaluationTypes";

export default {
  components: {
    EvaluationCard,
    Questions,
    EvaluationContent,
    Versions,
    FeedbackButton,
    RatingButton,
    Attempts
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    type: {
      type: String,
      required: true,
      validator: value => Object.values(evaluationTypes).includes(value)
    },
    hideFeedback: {
      type: Boolean,
      default: false
    },
    endpoint: {
      type: String,
      default: "learning.evaluations.read"
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();
    const store = useStore();

    // Composables
    const { request } = useRequest();
    const { getText } = useTexts();
    const { route } = useLesson();
    const { ALL_OPTIONS } = useOptions();

    // Data
    const data = ref({});
    const isLoading = ref(false);

    // Computed
    const isInstructor = computed(() => store.getters["user/isInstructor"]);
    const registration = computed(() => data.value?.registration_evaluation);
    const evaluation = computed(() => registration.value?.evaluation);
    const status = computed(() => evaluation.value?.status);
    // eslint-disable-next-line
    const isCompleted = computed(() => status.value === ALL_OPTIONS.COMPLETED.value);
    // eslint-disable-next-line
    const isPending = computed(() => status.value === ALL_OPTIONS.PENDING.value);
    const type = computed(() => evaluation.value?.type);
    // eslint-disable-next-line
    const isObservation = computed(() => type.value === ALL_OPTIONS.OBSERVATION.value);
    // eslint-disable-next-line
    const display_correct_answers = computed(() => evaluation.value?.display_correct_answers);
    // eslint-disable-next-line
    const displayCorrectAnswers = computed(() => display_correct_answers.value === ALL_OPTIONS.YES.value);
    const name = computed(() => getText(evaluation.value?.texts, "name"));
    const documentTitle = computed(() => {
      // eslint-disable-next-line
      const title = `${props.title} - ${t("app.evaluations", 2)} - ${t("app.learning")}`;

      if (name.value) {
        return `${name.value} - ${title}`;
      }

      return title;
    });

    // Methods
    const getData = async () => {
      const response = await request({
        endpoint: props.endpoint,
        pathParams: {
          id: props.id
        },
        showToaster: false
      });

      data.value = response?.payload?.data ?? {};
    };

    // Lifecycle Hooks
    onMounted(async () => {
      isLoading.value = true;
      await getData();
      isLoading.value = false;
    });

    return {
      evaluation,
      isObservation,
      isPending,
      isCompleted,
      name,
      data,
      isLoading,
      documentTitle,
      isInstructor,
      getData,
      feedbackRatingTypes,
      displayCorrectAnswers,
      // useLesson
      route
    };
  }
};
</script>
