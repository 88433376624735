export default function useTags() {
  const getTagValues = tags => {
    return tags ? tags.map(getTagValue) : [];
  };

  const getTagValue = tag => {
    return tag?.name ? tag.name : tag;
  };

  return {
    getTagValues
  };
}
