<template>
  <div>
    <Tippy
      tag="a"
      class="cursor-pointer tooltip"
      :content="$t('app.add_rating')"
    >
      <StarIcon class="text-theme-1" @click="showModal" />
    </Tippy>

    <FormModal
      v-if="isModalVisible"
      :id="modalId"
      :is-loading="isLoading"
      @click:cancel="hideModal"
      @click:confirm="onClickSave"
    >
      <template #header>
        {{ $t("app.add_rating") }}
      </template>

      <template #content>
        <div class="flex justify-center">
          <VStars v-model="v.rating.$model" />
        </div>
      </template>
    </FormModal>
  </div>
</template>

<script>
import { inject, ref } from "vue";
import { useI18n } from "vue-i18n";
import { required } from "@/i18n/i18n-validators";
// Composables
import useModal from "@/composables/useModal";
import useForm from "@/composables/useForm";
import useRequest from "@/composables/useRequest";
// Components
import FormModal from "@/components/modals/FormModal";
import VStars from "@/components/VStars";
// Constants
import feedbackRatingTypes from "@/constants/feedbackRatingTypes";

export default {
  components: {
    FormModal,
    VStars
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    type: {
      type: String,
      required: true,
      validator: value => Object.values(feedbackRatingTypes).includes(value)
    },
    name: {
      type: String,
      default: ""
    }
  },
  setup(props, context) {
    // Misc
    const { t } = useI18n();

    // Inject
    const _ = inject("lodash");
    const toast = inject("toast");

    // Constants
    const modalId = _.uniqueId();

    // Data
    const isLoading = ref(false);
    const value = ref({
      rating: 1
    });
    const rules = ref({
      rating: { required }
    });

    // Composables
    const { v, onSave } = useForm(props, context, {
      value,
      rules
    });
    const { isModalVisible, showModal, hideModal } = useModal(`#${modalId}`);
    const { request, isSuccess } = useRequest();

    // Methods
    const onClickSave = async () => {
      isLoading.value = true;

      const data = await onSave();

      if (!data) {
        isLoading.value = false;
        return;
      }

      const response = await addRating(data);
      const status = response?.payload?.message?.status;

      if (!isSuccess(response)) {
        isLoading.value = false;
        return;
      }

      if (props.name) {
        toast.show(
          t("app.add_rating_name_success_msg", {
            name: props.name,
            rating: value.value.rating
          }),
          status
        );
      } else {
        toast.show(
          t("app.add_rating_success_msg", { rating: value.value.rating }),
          status
        );
      }

      value.value.rating = "";
      await v.value.$reset();
      hideModal();

      isLoading.value = false;
    };

    const addRating = async data => {
      return await request({
        endpoint: "platform.rating.add",
        pathParams: {
          id: props.id,
          type: props.type
        },
        data,
        showToaster: false
      });
    };

    return {
      modalId,
      isLoading,
      onClickSave,
      // useForm
      v,
      // useModal
      isModalVisible,
      showModal,
      hideModal
    };
  }
};
</script>
