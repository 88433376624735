import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { required } from "@/i18n/i18n-validators";
// Composables
import useOptions from "@/composables/useOptions";
// Constants
import inputTypes from "@/constants/inputTypes";

export default function useLesson() {
  // Misc
  const { t } = useI18n();

  const { ALL_OPTIONS, defaultSystemOptions, getDepartments } = useOptions();

  // CONSTANTS
  const view_code = "Lessons";
  const route = "teaching-lessons";
  const endpoint = "teaching.lessons";
  const searchFields = ["name"];
  const localizedFields = ["name", "details", "content_location", "file_id"];

  const types = {
    name: inputTypes.TEXT,
    details: inputTypes.TEXT,
    duration: inputTypes.TEXT,
    duration_type: inputTypes.SELECT,
    status: inputTypes.SELECT,
    sys_admin_locked: inputTypes.SELECT,
    version: inputTypes.TEXT,
    available_from: inputTypes.DATE,
    available_until: inputTypes.DATE,
    type: inputTypes.SELECT,
    department_id: inputTypes.SELECT,
    tags: inputTypes.TAGS,
    date_activated: inputTypes.DATE,
    date_archived: inputTypes.DATE
  };

  const headers = [
    {
      value: "image",
      class: "w-10"
    },
    {
      text: t("app.name"),
      value: "name"
    },
    {
      text: t("app.version"),
      value: "version"
    },
    {
      text: t("app.type"),
      value: "type"
    },
    {
      text: t("app.status"),
      value: "status",
      class: "w-40"
    },
    {
      text: t("app.actions"),
      value: "__ACTIONS",
      class: "w-56"
    }
  ];

  // DATA
  const value = ref({
    name: null,
    details: null,
    duration: 0,
    duration_type: ALL_OPTIONS.MINUTES.value,
    status: ALL_OPTIONS.UNDER_REVIEW.value,
    sys_admin_locked: ALL_OPTIONS.NO.value,
    version: "1.0",
    available_from: "",
    available_until: "",
    content_location: "",
    file_id: "",
    type: ALL_OPTIONS.URL.value,
    department_id: "",
    tags: "",
    date_activated: "",
    date_archived: ""
  });

  const rules = ref({
    name: { required },
    details: {},
    duration: {},
    duration_type: {},
    status: {},
    sys_admin_locked: {},
    version: { required },
    available_from: {},
    available_until: {},
    content_location: {},
    file_id: {},
    type: { required },
    department_id: {},
    tags: {},
    date_activated: {},
    date_archived: {}
  });

  const options = ref({
    sys_admin_locked: defaultSystemOptions,
    status: [
      ALL_OPTIONS.ACTIVE,
      ALL_OPTIONS.UNDER_REVIEW,
      ALL_OPTIONS.ARCHIVED
    ],
    duration_type: [ALL_OPTIONS.HOURS, ALL_OPTIONS.MINUTES],
    type: [
      ALL_OPTIONS.DOCUMENT,
      ALL_OPTIONS.VIDEO,
      ALL_OPTIONS.URL,
      ALL_OPTIONS.AUDIO,
      ALL_OPTIONS.LIVE_LECTURE,
      ALL_OPTIONS.SLIDES
    ],
    department_id: []
  });

  // Computed
  const isTypeContentLocation = computed(() => {
    const type = value?.value?.type;

    if (!type) {
      return false;
    }

    return isContentLocation(type);
  });

  const isTypeFile = computed(() => {
    const type = value?.value?.type;

    if (!type) {
      return false;
    }

    return isFile(type);
  });

  const isTypeVideoUpload = computed(() => {
    const type = value?.value?.type;

    if (!type) {
      return false;
    }

    return isVideoUpload(type);
  });

  // METHODS
  const getOptions = async () => {
    options.value.department_id = await getDepartments();

    return options.value;
  };

  const isFile = value => {
    return [
      ALL_OPTIONS.DOCUMENT.value,
      ALL_OPTIONS.AUDIO.value,
      ALL_OPTIONS.SLIDES.value
    ].includes(value);
  };

  const isContentLocation = value => {
    return [ALL_OPTIONS.URL.value, ALL_OPTIONS.LIVE_LECTURE.value].includes(
      value
    );
  };

  const isVideoUpload = value => {
    return [ALL_OPTIONS.VIDEO.value].includes(value);
  };

  return {
    headers,
    view_code,
    types,
    route,
    endpoint,
    searchFields,
    getOptions,
    value,
    rules,
    options,
    localizedFields,
    isFile,
    isContentLocation,
    isTypeContentLocation,
    isVideoUpload,
    isTypeVideoUpload,
    isTypeFile
  };
}
