<template>
  <div>
    <i
      v-for="star in STARS"
      :key="star"
      :class="getClasses(star)"
      @mouseover="setStarHovered(star)"
      @mouseleave="setStarHovered(0)"
      @click="$emit('update:modelValue', star)"
    />
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  props: {
    modelValue: {
      type: [Number],
      default: 0,
      validator: value => value >= 0 && value <= 5
    }
  },
  emits: ["update:modelValue"],
  setup(props) {
    // Constants
    const STARS = [1, 2, 3, 4, 5];

    // Data
    const starHovered = ref(0);

    // Methods
    const getClasses = star => {
      if (props.modelValue >= star || starHovered.value >= star) {
        return "star fas fa-star active";
      }

      return "star fal fa-star";
    };

    const setStarHovered = star => {
      starHovered.value = star;
    };

    return {
      STARS,
      getClasses,
      setStarHovered
    };
  }
};
</script>

<style scoped>
.star {
  font-size: 40px;
  margin: 0 5px;
}

.active {
  color: rgb(240, 204, 21);
}
</style>
