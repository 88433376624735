// Composables
import useConfirmDelete from "@/composables/useConfirmDelete";
import useRequest from "@/composables/useRequest";

export default function useDelete(args) {
  const { endpoint, modalId, getData = () => {} } = args;

  // Composables
  const {
    isModalVisible,
    deletable,
    showDeleteModal,
    hideDeleteModal,
    confirmDelete
  } = useConfirmDelete(modalId);
  const { isRequesting: isDeleting, request } = useRequest();

  // METHODS
  const onClickDelete = async item => {
    await request({
      endpoint: `${endpoint}.delete`,
      pathParams: {
        id: item.id
      }
    });

    hideDeleteModal();

    await getData();
  };

  return {
    onClickDelete,
    // useRequest
    isDeleting,
    // useConfirmDelete
    isModalVisible,
    showDeleteModal,
    deletable,
    onClickConfirmDelete: confirmDelete,
    onClickCancelDelete: hideDeleteModal
  };
}
