<template>
  <div>
    <div v-if="displaySearch">
      <SearchField
        :field-options="fieldOptions"
        :is-searching="isSearching"
        @input="$emit('update:search', $event)"
      >
        <slot name="add-button">
          <VButton
            :label="$t('app.add')"
            class="btn-primary"
            @click="$emit('click:add')"
          />
        </slot>
      </SearchField>
    </div>

    <div class="col-span-12 overflow-auto lg:overflow-visible">
      <div v-if="!hideHeader" class="flex justify-between items-center">
        <VTitle class="section-title">
          <slot name="title">
            {{ displayedTitle }}
          </slot>
        </VTitle>

        <slot name="title-actions" />
      </div>

      <div v-if="isLoading" class="flex justify-center">
        <LoadingIcon icon="three-dots" class="w-8 h-8" />
      </div>

      <VAlert v-else-if="rows.length === 0" :text="emptyStateLabel" />

      <div v-else>
        <table class="table table-report -mt-2">
          <thead v-if="!hideHeaders">
            <tr>
              <th v-if="multiple">
                <VCheckbox
                  :model-value="hasSelectedAll"
                  @update:modelValue="selectAll"
                />
              </th>

              <th
                v-for="(header, index) in headHeaders"
                :key="`${header.value}-${index}`"
                class="whitespace-nowrap uppercase"
                :class="{ 'image-padding': header.value === 'image' }"
              >
                <slot
                  :name="`header.${header.value}`"
                  :header="header"
                  :index="index"
                >
                  <div
                    class="flex"
                    :class="[
                      header.headerClass,
                      {
                        'justify-center':
                          header.value === '__ACTIONS' ||
                          header.value === 'status'
                      }
                    ]"
                  >
                    {{ header.text }}
                  </div>
                </slot>
              </th>

              <th v-if="hasSelectedMultiple">
                <DropdownButton
                  button-class="w-full"
                  :label="$t('app.actions')"
                  :options="actionOptions"
                  @click="$event.click()"
                />
              </th>
            </tr>
          </thead>

          <tbody>
            <!-- eslint-disable-next-line -->
            <template v-for="(row, rowIndex) in rows" :key="rowIndex">
              <tr :class="row.rowClass" @click="$emit('click:row', row)">
                <td v-if="multiple" class="w-0">
                  <VCheckbox
                    :model-value="hasItem(row.id)"
                    @update:modelValue="select(row)"
                  />
                </td>
                <td
                  v-for="(header, headerIndex) in headers"
                  :key="`row-${header.value}-${headerIndex}`"
                  :class="[
                    { 'table-report__action': header.value === '__ACTIONS' },
                    { 'image-padding': header.value === 'image' },
                    header.class,
                    header.cellClass
                  ]"
                  @click="$emit(`click:${header.value}`, row)"
                >
                  <slot
                    :name="`item.${header.value}`"
                    :item="row"
                    :index="rowIndex"
                  >
                    {{ row[header.value] }}
                  </slot>
                </td>
              </tr>

              <template
                v-if="
                  expandedIndexes.includes(rowIndex) && $slots['expanded-item']
                "
              >
                <tr>
                  <td :colspan="headers.length" class="expanded-td">
                    <slot name="expanded-item" :item="row" :index="rowIndex" />
                  </td>
                </tr>

                <tr />
                <tr />
              </template>
            </template>
          </tbody>
        </table>

        <FullPagination
          v-if="displayPagination"
          :pagination="pagination"
          :hide-elements-per-page="hideElementsPerPage"
          @update:current_page="$emit('update:current_page', $event)"
          @update:per_page="$emit('update:per_page', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useTable from "@/composables/useTable";
// Components
import SearchField from "@/components/SearchField.vue";
import VButton from "@/components/VButton.vue";
import FullPagination from "@/components/tables/FullPagination";
import VAlert from "@/components/VAlert";
import VTitle from "@/components/VTitle";
import VCheckbox from "@/components/inputs/VCheckbox";
import DropdownButton from "@/components/DropdownButton";

export default {
  components: {
    SearchField,
    VTitle,
    FullPagination,
    VButton,
    VAlert,
    VCheckbox,
    DropdownButton
  },
  props: {
    headers: {
      type: Array,
      default: () => []
    },
    rows: {
      type: Array,
      default: () => []
    },
    displayPagination: {
      type: Boolean,
      default: false
    },
    displaySearch: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    hideHeader: {
      type: Boolean,
      default: false
    },
    hideHeaders: {
      type: Boolean,
      default: false
    },
    // @TODO: use provide/inject instead
    isSearching: {
      type: Boolean,
      default: false
    },
    // @TODO: use provide/inject instead
    hideElementsPerPage: {
      type: Boolean,
      default: false
    },
    // @TODO: use provide/inject instead
    pagination: {
      type: Object,
      default: () => ({
        current_page: 1,
        per_page: 20,
        total: 0
      })
    },
    // @TODO: use provide/inject instead
    fieldOptions: {
      type: Array,
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    expandedIndexes: {
      type: Array,
      default: () => []
    },
    emptyStateMessage: {
      type: String,
      default: ""
    }
  },
  emits: [
    "click:add",
    "update:current_page",
    "update:per_page",
    "update:search",
    "click:search",
    "click:row"
  ],
  setup(props, context) {
    // Misc
    const { t } = useI18n();

    // Composables
    const {
      hasSelectedMultiple,
      hasSelectedAll,
      selected,
      hasItem,
      selectAll,
      select,
      headHeaders,
      actionOptions
    } = useTable(props, context);

    // Computed
    const displayedTitle = computed(() => {
      return props.title ? props.title : t("app.search_results");
    });

    const emptyStateLabel = computed(() => {
      return props.emptyStateMessage || t("app.no_results_found");
    });

    return {
      emptyStateLabel,
      displayedTitle,
      // useTable
      hasSelectedMultiple,
      hasSelectedAll,
      selected,
      hasItem,
      selectAll,
      select,
      headHeaders,
      actionOptions
    };
  }
};
</script>

<style scoped>
.image-padding {
  padding-right: 0px !important;
}

.expanded-row,
.expanded-data {
  background-color: transparent !important;
  box-shadow: none !important;
}

.expanded-data {
  padding: 0 !important;
  /* padding-top: 0 !important; */
  /* padding-bottom: 0 !important; */
}

.expanded-td {
  background-color: transparent !important;
  box-shadow: none !important;
}
</style>
