<template>
  <VList :rows="rows" :headers="headers" @click:row="onClickRow">
    <template #title>
      <div class="text-base font-medium">
        {{ $t("app.attempts", 2) }}
      </div>
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.result="{ item }">
      <div class="flex justify-center">
        <VChip
          :text="$t(`app.${item?.result}`)"
          :class="getStatusColor(item?.result)"
        />
      </div>
    </template>
  </VList>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
// Composables
import useColor from "@/composables/useColor";
import useDisplay from "@/composables/useDisplay";
// Components
import VList from "@/components/tables/VList";
import VChip from "@/components/VChip";
// Constants
import evaluationTypes from "@/constants/evaluationTypes";

export default {
  components: {
    VList,
    VChip
  },
  props: {
    attempts: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      required: true,
      validator: value => Object.values(evaluationTypes).includes(value)
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();
    const router = useRouter();

    // Composables
    const { formatDate } = useDisplay();
    const { getStatusColor } = useColor();

    // Constants
    const headers = [
      {
        text: t("app.started_on"),
        value: "displayed_started_on"
      },
      {
        text: t("app.completed_on"),
        value: "displayed_learner_completed_on"
      },
      {
        text: t("app.grade"),
        value: "grade"
      },
      {
        text: t("app.result"),
        value: "result",
        headerClass: "justify-center"
      }
    ];

    // Computed
    const rows = computed(() => {
      return props.attempts.map(attempt => ({
        ...attempt,
        displayed_started_on: formatDate({ date: attempt.started_on }),
        rowClass: "cursor-pointer",
        // eslint-disable-next-line
        displayed_learner_completed_on: formatDate({ date: attempt.learner_completed_on })
      }));
    });

    // Methods
    const onClickRow = row => {
      const types = {
        [evaluationTypes.QUIZ]: "quizzes",
        [evaluationTypes.OBSERVATION]: "observations"
      };
      const type = types[props.type];

      if (!type) {
        return;
      }

      router.push({
        name: `learning-${type}-details`,
        params: {
          id: row?.id
        }
      });
    };

    return {
      onClickRow,
      rows,
      headers,
      // useColor
      getStatusColor
    };
  }
};
</script>
