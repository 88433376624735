<template>
  <div>
    <Tippy
      tag="a"
      class="cursor-pointer tooltip"
      :content="$t('app.add_feedback')"
    >
      <MessageCircleIcon class="text-theme-1" @click="showModal" />
    </Tippy>

    <FormModal
      v-if="isModalVisible"
      :id="modalId"
      :is-loading="isLoading"
      @click:cancel="hideModal"
      @click:confirm="onClickSave"
    >
      <template #header>
        {{ $t("app.add_feedback") }}
      </template>

      <template #content>
        <VWysiwyg
          v-model="v.comment.$model"
          :label="$t('app.comments')"
          :errors="v.comment.$errors"
        />
      </template>
    </FormModal>
  </div>
</template>

<script>
import { inject, ref } from "vue";
import { required } from "@/i18n/i18n-validators";
// Composables
import useModal from "@/composables/useModal";
import useForm from "@/composables/useForm";
import useRequest from "@/composables/useRequest";
// Components
import FormModal from "@/components/modals/FormModal";
import VWysiwyg from "@/components/inputs/VWysiwyg";
// Constants
import feedbackRatingTypes from "@/constants/feedbackRatingTypes";

export default {
  components: {
    FormModal,
    VWysiwyg
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    type: {
      type: String,
      required: true,
      validator: value => Object.values(feedbackRatingTypes).includes(value)
    }
  },
  setup(props, context) {
    // Inject
    const _ = inject("lodash");

    // Constants
    const modalId = _.uniqueId();

    // Data
    const isLoading = ref(false);
    const value = ref({
      comment: ""
    });
    const rules = ref({
      comment: { required }
    });

    // Composables
    const { v, onSave } = useForm(props, context, { value, rules });
    const { isModalVisible, showModal, hideModal } = useModal(`#${modalId}`);
    const { request, isSuccess } = useRequest();

    // Methods
    const onClickSave = async () => {
      isLoading.value = true;

      const data = await onSave();

      if (!data) {
        isLoading.value = false;
        return;
      }

      const response = await addFeedback(data);

      if (!isSuccess(response)) {
        isLoading.value = false;
        return;
      }

      hideModal();
      value.value.comment = "";
      await v.value.$reset();

      isLoading.value = false;
    };

    const addFeedback = async data => {
      return await request({
        endpoint: "platform.feedback.add",
        pathParams: {
          id: props.id,
          type: props.type
        },
        data
      });
    };

    return {
      modalId,
      isLoading,
      onClickSave,
      // useForm
      v,
      // useModal
      isModalVisible,
      showModal,
      hideModal
    };
  }
};
</script>
