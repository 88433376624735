import { ref } from "vue";
import useModal from "@/composables/useModal";

export default function useConfirmDelete(modalId) {
  // Composables
  const { isModalVisible, showModal, hideModal } = useModal(modalId);

  // Data
  const deletable = ref(null);

  // Methods
  const confirmDelete = item => {
    deletable.value = item;
    showModal();
  };

  return {
    deletable,
    isModalVisible,
    hideDeleteModal: hideModal,
    showDeleteModal: showModal,
    confirmDelete
  };
}
