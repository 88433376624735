<template>
  <div>
    <VCard
      :title-class="hideActions || isView ? '' : 'cursor-pointer'"
      actions-class="bg-theme-5"
      @click:title="redirectToEvaluation"
    >
      <template #title>
        <slot name="title" :resource="resource">
          <div class="flex items-center">
            <VImage :color="color" :name="name" class="mr-2" />

            <div>
              <div class="font-medium">
                {{ name }}
              </div>

              <div class="version">
                {{ $t("app.versions") }} {{ resource?.evaluation?.version }}
              </div>
            </div>
          </div>
        </slot>
      </template>

      <template #title-actions>
        <VChip
          v-if="resource?.status"
          :text="$t(`app.${resource?.status}`)"
          :class="getStatusColor(resource?.status)"
        />
      </template>

      <template #content>
        <div class="grid grid-cols-2 gap-2">
          <div v-if="details" class="col-span-2">
            {{ details }}
          </div>

          <VLine
            v-if="trainingName"
            :label="$t('app.trainings')"
            :value="trainingName"
            :class="[
              { 'col-span-2': isObservation },
              { 'grid col-span-2 grid-cols-2': isView }
            ]"
          />

          <VLine
            v-if="!isObservation"
            :label="$t('app.duration')"
            :class="{ 'grid col-span-2 grid-cols-2': isView }"
          >
            <template #value>
              <div class="lowercase">
                {{ duration }}
              </div>
            </template>
          </VLine>

          <VLine
            v-if="resource?.started_on"
            :label="$t('app.started_on')"
            :class="{ 'grid col-span-2 grid-cols-2': isView }"
          >
            <template #value>
              <div>
                <VDate :date="resource?.started_on" />
              </div>
            </template>
          </VLine>

          <VLine
            v-if="resource?.learner_completed_on"
            :class="{ 'grid col-span-2 grid-cols-2': isView }"
            :label="$t('app.completed_on')"
          >
            <template #value>
              <div class="flex items-center">
                <VDate class="space" :date="resource?.learner_completed_on" />

                <div class="space lowercase label">
                  {{ $t("app.in") }}
                </div>

                <div>
                  {{ completionTime }}
                </div>
              </div>
            </template>
          </VLine>

          <VLine
            v-if="isObservation && resource?.observation_requested_on"
            :class="{ 'grid col-span-2 grid-cols-2': isView }"
            :label="$t('app.observation_requested_on')"
          >
            <template #value>
              <div>
                <VDate :date="resource?.observation_requested_on" />
              </div>
            </template>
          </VLine>

          <VLine
            v-if="isObservation && resource?.observation_scheduled_on"
            :class="{ 'grid col-span-2 grid-cols-2': isView }"
            :label="$t('app.observation_scheduled_on')"
          >
            <template #value>
              <div>
                <VDate :date="resource?.observation_scheduled_on" />
              </div>
            </template>
          </VLine>

          <VLine
            v-if="isObservation && observer"
            :class="{ 'grid col-span-2 grid-cols-2': isView }"
            :label="$t('app.observer')"
            :value="observer"
            class="col-span-2"
          />

          <VLine
            v-if="hasCompletionData"
            :class="{ 'grid col-span-2 grid-cols-2': isView }"
            :label="$t('app.completion_requested_on')"
          >
            <template #value>
              <div>
                <VDate :date="resource?.completion_requested_on" />
              </div>
            </template>
          </VLine>

          <VLine
            v-if="hasCompletionData && resource?.completion_approved_on"
            :class="{ 'grid col-span-2 grid-cols-2': isView }"
            :label="$t('app.completion_approved_by')"
          >
            <template #value>
              <div class="flex items-center">
                <div class="space">
                  {{ completion_approved_by }}
                </div>

                <div class="space lowercase label">
                  {{ $t("app.on") }}
                </div>

                <VDate :date="resource?.completion_approved_on" />
              </div>
            </template>
          </VLine>

          <VLine
            v-if="isCompleted"
            class="items-center"
            :class="{ 'grid col-span-2 grid-cols-2': isView }"
            :label="$t('app.result')"
          >
            <template #value>
              <div class="flex items-center">
                <div
                  class="lesson-type self-center"
                  :class="hasPassed ? 'success' : 'failure'"
                >
                  {{ result }}
                </div>

                <div class="space lowercase label">
                  {{ $t("app.with") }}
                </div>

                <div
                  class="lesson-type"
                  :class="isPassingGrade ? 'success' : 'failure'"
                >
                  {{ grade }}%
                </div>
              </div>
            </template>
          </VLine>

          <VLine
            v-if="comments"
            class="items-start"
            :class="{ 'grid col-span-2 grid-cols-2': isView }"
            :label="commentsLabel"
            :value="comments"
          />

          <VLine
            v-if="files.length !== 0"
            class="items-start"
            :class="{ 'grid col-span-2 grid-cols-2': isView }"
            :label="$t('app.files')"
          >
            <template #value>
              <FileItems :files="files" />
            </template>
          </VLine>
        </div>
      </template>

      <template v-if="!isView && !hideActions" #actions>
        <div
          class="flex"
          :class="hasActions ? 'justify-between' : 'justify-end'"
        >
          <div v-if="isPending">
            <VButton
              v-if="evaluationType === evaluationTypes.QUIZ"
              class="btn-outline-primary"
              :label="$t('app.start')"
              @click="showModal"
            />

            <VButton
              v-if="evaluationType === evaluationTypes.OBSERVATION"
              class="btn-outline-primary"
              :label="$t('app.request_observation')"
              :is-loading="isRequesting"
              @click="requestObservation"
            />
          </div>

          <div v-if="isInProgress">
            <SubmitEvaluationButton
              v-if="evaluationType === evaluationTypes.QUIZ"
              :id="resource?.id"
              @submitted="$emit('refresh')"
            />
          </div>

          <VButton
            class="btn-primary"
            :label="$t('app.view_evaluation')"
            @click="redirectToEvaluation"
          />
        </div>
      </template>
    </VCard>

    <WarningModal
      v-if="isModalVisible"
      :id="resource?.id"
      :warning="$t('app.quiz_start_msg')"
    >
      <template #actions>
        <div class="px-5 pb-8 text-center">
          <VButton
            class="btn-outline-secondary mr-2"
            :label="$t('app.cancel')"
            @click="hideModal"
          />

          <VButton
            class="btn-primary"
            :label="$t('app.start')"
            :is-loading="isStarting"
            @click="startQuiz"
          />
        </div>
      </template>
    </WarningModal>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
// Composables
import useRequest from "@/composables/useRequest";
import useTexts from "@/composables/useTexts";
import useOptions from "@/composables/useOptions";
import useColor from "@/composables/useColor";
import useDisplay from "@/composables/useDisplay";
import useModal from "@/composables/useModal";
// Components
import SubmitEvaluationButton from "./SubmitEvaluationButton";
import VCard from "@/components/VCard";
import VChip from "@/components/VChip";
import VDate from "@/components/VDate";
import VLine from "@/components/VLine";
import FileItems from "@/components/FileItems";
import VButton from "@/components/VButton";
import VImage from "@/components/VImage";
import WarningModal from "@/components/modals/WarningModal";
// Constants
import evaluationTypes from "@/constants/evaluationTypes";

export default {
  components: {
    FileItems,
    SubmitEvaluationButton,
    VCard,
    VDate,
    VChip,
    VButton,
    VLine,
    VImage,
    WarningModal
  },
  props: {
    resource: {
      type: Object,
      required: true
    },
    evaluationType: {
      type: String,
      required: true,
      validator: value => Object.values(evaluationTypes).includes(value)
    },
    isView: {
      type: Boolean,
      default: false
    },
    hideActions: {
      type: Boolean,
      default: false
    }
  },
  emits: ["click", "refresh"],
  setup(props, context) {
    // Misc
    const { t } = useI18n();
    const router = useRouter();

    // Data
    const isStarting = ref(false);

    // Composables
    const { getStatusColor } = useColor();
    const { getText } = useTexts();
    const { trimHTML, formatSeconds } = useDisplay();
    const { ALL_OPTIONS } = useOptions();
    const { isRequesting, request } = useRequest();
    // eslint-disable-next-line
    const { isModalVisible, hideModal, showModal } = useModal(`#${props.resource?.id}`);

    // Computed
    const grade = computed(() => props.resource?.grade);
    const completion_time = computed(() => props.resource?.completion_time);
    const completionTime = computed(() => formatSeconds(completion_time.value));
    // eslint-disable-next-line
    const files = computed(() => props.resource?.files.map(file => ({ ...file, ...file.file })) ?? []);
    const observer_comments = computed(() => props.resource?.observer_comments);
    const learner_comments = computed(() => props.resource?.learner_comments);
    // eslint-disable-next-line
    const comments = computed(() => trimHTML(isObservation.value ? observer_comments.value : learner_comments.value));
    const result = computed(() => props.resource?.result);
    const hasPassed = computed(() => result.value === ALL_OPTIONS.PASSED.value);
    const status = computed(() => props.resource?.status);
    // eslint-disable-next-line
    const isPending = computed(() => status.value === ALL_OPTIONS.PENDING.value);
    // eslint-disable-next-line
    const isInProgress = computed(() => status.value === ALL_OPTIONS.IN_PROGRESS.value);
    // eslint-disable-next-line
    const isCompleted = computed(() => status.value === ALL_OPTIONS.COMPLETED.value);
    const registration = computed(() => props.resource?.registration);
    const training = computed(() => registration.value?.training);
    const trainingName = computed(() => getText(training.value?.texts, "name"));
    const evaluation = computed(() => props.resource?.evaluation);
    const name = computed(() => getText(evaluation.value?.texts, "name"));
    const details = computed(() => getText(evaluation.value?.texts, "details"));
    const color = computed(() => evaluation.value?.colour);
    const durationType = computed(() => evaluation.value?.duration_type);
    const durationTime = computed(() => evaluation.value?.duration);
    // eslint-disable-next-line
    const requiresApproval = computed(() => evaluation.value?.requires_approval);
    const type = computed(() => evaluation.value?.type);
    // eslint-disable-next-line
    const isObservation = computed(() => type.value === ALL_OPTIONS.OBSERVATION.value);
    const passingGrade = computed(() => evaluation.value?.passing_grade);
    // eslint-disable-next-line
    const duration = computed(() => durationTime.value && durationType.value ? `${durationTime.value} ${t(`app.${durationType.value}`)}` : "");
    // eslint-disable-next-line
    const hasRequiresApproval = computed(() => requiresApproval.value === ALL_OPTIONS.YES.value)
    const isPassingGrade = computed(() => grade.value >= passingGrade.value);
    // eslint-disable-next-line
    const hasCompletionData = computed(() => hasRequiresApproval.value && hasPassed.value);
    const completion_approved_by = computed(() => {
      const { firstname, lastname } = props.resource?.approver ?? {};

      return firstname && lastname ? `${firstname} ${lastname}` : "";
    });
    // eslint-disable-next-line
    const hasActions = computed(() => isPending.value || (isInProgress.value && !isObservation.value));
    const observer = computed(() => {
      const { firstname, lastname } = props.resource?.observer ?? {};

      return firstname && lastname ? `${firstname} ${lastname}` : "";
    });
    // eslint-disable-next-line
    const commentsLabel = computed(() => isObservation.value ? t("app.observer_comments") : t("app.learner_comments"));

    // Methods
    const startQuiz = () => {
      isStarting.value = true;
      hideModal();

      /**
       * @WARNING
       * this is wrapped in a setTimeout because sometimes it redirect before it has
       * time to hide the modal, and since the modal is hidden based on its id in the DOM
       * and with the redirection, the id is no longer in the DOM, the modal can never get hidden
       */
      setTimeout(() => {
        router.push({
          name: "begin-quiz",
          params: {
            id: props.resource?.id
          }
        });
      }, 500);

      isStarting.value = false;
    };

    const requestObservation = async () => {
      await request({
        endpoint: "learning.evaluations.request",
        pathParams: {
          id: props.resource?.id
        }
      });

      context.emit("refresh");
    };

    const redirectToEvaluation = () => {
      if (!props.resource?.id) {
        return;
      }

      context.emit("click", props.resource?.id, props.evaluationType);
    };

    return {
      files,
      comments,
      commentsLabel,
      isCompleted,
      isStarting,
      hasActions,
      observer,
      isObservation,
      isPassingGrade,
      hasPassed,
      completion_approved_by,
      grade,
      result,
      name,
      color,
      details,
      completionTime,
      hasCompletionData,
      duration,
      trainingName,
      startQuiz,
      evaluationTypes,
      isPending,
      isInProgress,
      requestObservation,
      redirectToEvaluation,
      // useColor
      getStatusColor,
      // useRequest
      isRequesting,
      // useModal
      isModalVisible,
      hideModal,
      showModal
    };
  }
};
</script>
