<template>
  <div class="file-item cursor-pointer" @click="onClickDownload">
    {{ file.name }}
  </div>
</template>

<script>
// Composables
import useDownload from "@/composables/useDownload";

export default {
  props: {
    file: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    // Composables
    const { downloadFile } = useDownload();

    // Methods
    const onClickDownload = () => {
      downloadFile(props.file?.download_path);
    };

    return {
      onClickDownload
    };
  }
};
</script>

<style scoped>
.file-item {
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
  margin-right: 0.25rem;
  border-radius: 0.25rem;
  background-color: rgba(226, 232, 240, 1);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
  cursor: pointer;
  line-height: 22px;
  border: 0 solid #d0d0d0;
  color: #303030;
}
</style>
