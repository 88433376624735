<template>
  <div class="chip whitespace-nowrap">
    {{ text }}
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: [Number, String],
      required: true
    }
  }
};
</script>

<style scoped lang="scss">
.chip {
  $white-bg: rgba(255, 255, 255, 1);
  $border-opacity: 1;
  $text-opacity: 1;
  $bg-opacity: 0.2;

  @apply flex justify-center items-center rounded-3xl px-4 py-0.5 font-medium cursor-default h-8;

  &.white {
    @apply bg-white;
  }

  &.gray {
    $border-color: rgb(226, 232, 240);
    $bg-color: rgb(203, 213, 225);
    $text-color: rgb(100, 116, 139);

    &.status {
      background-color: rgba($bg-color, $bg-opacity);
      border-color: rgba($border-color, $border-opacity);
      color: rgba($text-color, $text-opacity);

      @apply border;
    }

    &.action {
      background-color: $white-bg;
      color: rgba($bg-color, $text-opacity);
      border-color: $text-color;

      @apply border;
    }
  }

  &.dark {
    $bg-color: rgb(51, 65, 85);
    $border-opacity: 0.05;

    &.status {
      background-color: rgba($bg-color, $bg-opacity);
      border-color: rgba($bg-color, $border-opacity);
      color: rgba($bg-color, $text-opacity);

      @apply border;
    }

    &.action {
      background-color: $white-bg;
      color: rgba($bg-color, $text-opacity);
      border-color: $bg-color;

      @apply border;
    }
  }

  &.orange {
    $bg-color: rgba(249, 115, 22);
    $border-opacity: 0.05;

    &.status {
      background-color: rgba($bg-color, $bg-opacity);
      border-color: rgba($bg-color, $border-opacity);
      color: rgba($bg-color, $text-opacity);

      @apply border;
    }

    &.action {
      background-color: $white-bg;
      color: rgba($bg-color, $text-opacity);
      border-color: $bg-color;

      @apply border;
    }
  }

  &.green {
    $bg-color: rgba(132, 204, 22);
    $text-color: rgb(109, 170, 19);
    $border-opacity: 0.05;

    &.status {
      background-color: rgba($bg-color, $bg-opacity);
      border-color: rgba($bg-color, $border-opacity);
      color: rgba($text-color, $text-opacity);

      @apply border;
    }

    &.action {
      background-color: $white-bg;
      color: rgba($text-color, $text-opacity);
      border-color: $bg-color;

      @apply border;
    }
  }

  &.yellow {
    $bg-color: rgb(250, 204, 21);

    &.rank {
      background-color: rgba(251, 197, 0, 1);
      color: rgba(0, 0, 0, 1);
    }

    &.status {
      background-color: rgba($bg-color, $bg-opacity);
      border-color: rgba($bg-color, $border-opacity);
      color: rgba($bg-color, $text-opacity);

      @apply border;
    }

    &.action {
      background-color: $white-bg;
      color: rgba($bg-color, $text-opacity);
      border-color: $bg-color;

      @apply border;
    }
  }

  &.red {
    $bg-color: rgb(220, 38, 38);

    &.rank {
      background-color: rgba(211, 41, 41, 1);
      color: rgb(255, 255, 255);
    }

    &.status {
      background-color: rgba($bg-color, $bg-opacity);
      border-color: rgba($bg-color, $border-opacity);
      color: rgba($bg-color, $text-opacity);

      @apply border;
    }

    &.action {
      background-color: $white-bg;
      color: rgba($bg-color, $text-opacity);
      border-color: $bg-color;

      @apply border;
    }
  }

  &.blue {
    $bg-color: rgb(30, 64, 175);

    &.status {
      background-color: rgba($bg-color, 0.1);
      border-color: rgba($bg-color, $border-opacity);
      color: rgba($bg-color, $text-opacity);

      @apply border;
    }

    &.action {
      background-color: $white-bg;
      color: rgba($bg-color, $text-opacity);
      border-color: $bg-color;

      @apply border;
    }
  }
}
</style>
