<template>
  <div class="flex items-center">
    <input
      :id="id"
      :disabled="disabled || readonly"
      class="form-check-input mr-2"
      type="radio"
      :name="name"
      :value="id"
      :checked="isValueChecked"
      :class="[inputClass, { 'border-theme-6': hasErrors }]"
      @click="onInput"
    />

    <slot :id="id" name="label">
      <label v-if="label" :for="id" class="form-label mb-0">
        {{ label }}
      </label>
    </slot>

    <VErrors :errors="errors" />
  </div>
</template>

<script>
import { inject, ref, computed } from "vue";
// Components
import VErrors from "@/components/inputs/VErrors";

export default {
  components: {
    VErrors
  },
  props: {
    name: {
      type: [String, Number],
      required: true
    },
    modelValue: {
      type: [String, Number, Boolean],
      default: ""
    },
    trueValue: {
      type: [String, Boolean],
      default: true
    },
    falseValue: {
      type: [String, Boolean],
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    inputClass: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:modelValue"],
  setup(props, context) {
    // Misc
    const _ = inject("lodash");

    // Data
    const id = ref(_.uniqueId());

    // Computed
    const isValueChecked = computed(() => {
      return props.modelValue === props.trueValue;
    });

    const hasErrors = computed(() => {
      return props.errors && Object.keys(props.errors).length > 0;
    });

    // Methods
    const onInput = () => {
      context.emit("update:modelValue", id.value);
    };

    return {
      hasErrors,
      id,
      isValueChecked,
      onInput
    };
  }
};
</script>
