import { computed, watch } from "vue";
import { useStore } from "@/store";
import useVuelidate from "@vuelidate/core";

/**
 * @param {Ref<Object>} value - ref value
 * @param {Ref<Object>} rules - ref rules
 * @param {Array} localizedFields - the fields that are multi language
 * @param {Ref<String>} lang - ref lang
 * @returns {Object}
 */
export default function useLocalizedInputs(
  value,
  rules,
  localizedFields = [],
  lang = ""
) {
  // MISC
  const store = useStore();

  // Constants
  const localizedValue = value?.value;
  const localizedRules = rules?.value;

  // COMPUTED
  const langs = computed(() => store.getters["app/available_languages"]);
  const langKeys = computed(() => Object.keys(langs.value));

  const formattedValue = computed(() => {
    return Object.keys(value.value).reduce(
      (acc, cv) => {
        if (localizedFields.includes(cv)) {
          Object.entries(value.value[cv]).forEach(([key, v]) => {
            const index = acc.texts.findIndex(x => x.language === key);

            if (index === -1) {
              acc.texts.push({ language: key, [cv]: v.value || null });
            } else {
              acc.texts[index] = { ...acc.texts[index], [cv]: v.value || null };
            }
          });

          return acc;
        }

        return { ...acc, [cv]: value.value[cv] };
      },
      { texts: [] }
    );
  });

  const stringifiedValue = computed(() => {
    const texts = formattedValue.value.texts;

    if (texts.length === 0) {
      delete formattedValue.value.texts;
    }

    return {
      ...formattedValue.value,
      ...(texts.length > 0 ? { texts: JSON.stringify(texts) } : {})
    };
  });

  // METHODS
  /**
   * Set the value based on the localizedFields and the langs
   *
   * For example:
   * value { name: "", details: "" }
   * localizedFields ["name"]
   * langs {en: "English", fr: "Francais" }
   * will return
   * { name: { en: { value: "" }, fr: { value: "" } }, details: "" }
   *
   * @param {Object} value - the value
   * @returns {Object} formatted value
   */
  const setValue = (value = {}) => {
    return Object.keys(value).reduce((acc, key) => {
      if (localizedFields.includes(key)) {
        const v = langKeys.value.reduce((values, cv) => {
          return {
            ...values,
            [cv]: {
              value: value[key]
            }
          };
        }, {});

        return { ...acc, [key]: v };
      }

      return { ...acc, [key]: value[key] };
    }, {});
  };

  /**
   * Set the rules based on the localizedFields, the langs and the selected lang
   *
   * For example:
   * value { name: { required }, details: { required } }
   * localizedFields ["name"]
   * langs { en: "English", fr: "Francais" }
   * lang "en"
   * will return
   * { name: { en: { value: { required } }, fr: { value: {} } }, details: { required } }
   *
   * @param {Object} value - the rules
   * @returns {Object} formatted rules
   */
  const setRules = (value = {}) => {
    return Object.keys(value).reduce((acc, key) => {
      if (localizedFields.includes(key)) {
        const v = langKeys.value.reduce((values, cv) => {
          if (lang.value === "" || lang.value === cv) {
            return {
              ...values,
              [cv]: {
                // $autoDirty: true,
                value: value[key]
              }
            };
          }

          return {
            ...values,
            [cv]: {
              value: {}
            }
          };
        }, {});

        return { ...acc, [key]: v };
      }

      return { ...acc, [key]: value[key] };
    }, {});
  };

  const clearValues = () => {
    Object.keys(value.value).forEach(key => {
      if (localizedFields.includes(key)) {
        clearLocalizedValue(value.value[key]);
      } else {
        value.value[key] = "";
      }
    });
  };

  const clearLocalizedValue = field => {
    Object.keys(field).forEach(key => {
      field[key].value = "";
    });
  };

  const onUpdate = e => {
    if (!value.value[e.field]) {
      value.value = {
        ...value.value,
        [e.field]: {}
      };
    }

    if (!value.value[e.field][e.lang]) {
      value.value[e.field] = {
        ...value.value[e.field],
        [e.lang]: {
          value: null
        }
      };
    }

    value.value[e.field][e.lang].value = e.value;
  };

  if (value?.value) {
    value.value = setValue(localizedValue);
  }

  if (rules?.value) {
    rules.value = setRules(localizedRules);
  }

  const v = useVuelidate(rules, value);

  // Watch
  watch(
    () => lang,
    () => {
      rules.value = setRules(localizedRules);
    }
  );

  return {
    v,
    onUpdate,
    stringifiedValue,
    clearValues,
    clearLocalizedValue
  };
}
