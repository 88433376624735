<!-- eslint-disable -->
<template>
  <div class="flex">
    <Tippy
      v-if="src && content"
      tag="img"
      class="rounded-full"
      :class="`w-${width} h-${height}`"
      :content="content"
      :src="src"
    />

    <div v-else-if="src" :class="`w-${width} h-${height}`">
      <img class="rounded-full" :src="src" />
    </div>

    <div
      v-else
      :class="`w-${width} h-${height}`"
      class="relative inline-block align-middle rounded-full border"
      :style="{ 'background-color': color }"
    >
      <div
        v-if="name"
        class="left-1/2 top-1/2 absolute transform -translate-x-1/2 -translate-y-1/2"
        :class="nameClass"
        :style="{ 'color': textColor }"
      >
        {{ initials }}
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import useColor from "@/composables/useColor";

export default {
  props: {
    src: {
      type: String,
      default: ""
    },
    content: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    nameClass: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: "#000000"
    },
    width: {
      type: [Number, String],
      default: 10
    },
    height: {
      type: [Number, String],
      default: 10
    }
  },
  setup(props) {
    const { getContrastingColor } = useColor();

    // Computed
    const textColor = computed(() => {
      return getContrastingColor(props.color);
    });

    const initials = computed(() => {
      return props.name
        .split(" ")
        .slice(0, 2)
        .map(x => x.charAt(0))
        .join("")
        .toUpperCase();
    });

    return {
      textColor,
      initials
    };
  }
};
</script>
